:root {
    // color-scheme: light dark;

    // Ui Colors
    --ui-color: #00FA9A;
    --gradient-color: #1cd9d2;

    // Light theme
    --font-color-light: #333333;
    --font-color-info-light: rgb(33, 33, 33, 0.6);

    // Dark theme
    --font-color-dark: #fff;
    --font-color-info-dark: rgba(255, 255, 255, 0.6);
    //--background: #1C1B22;
    --background: #000;

    // Fonts
    --default-font: 'Outfit';
    --headline-font: 'Interval';
    --content-font-weight: 350;
    --menu-font-weight: 500;

    // Max widths for media queries
    --max-width: 1450px;
    --max-width-medium: 1260px;
    --max-width-small: 1100px;
    --max-width-mobile: 935px;

    // Buttons
    --button-width: 32px;
    --button-height: var(--button-width);

    // Embedded gradient
    --gradient-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="1000px" height="1000px"><defs><linearGradient id="g1" x1="100%" y1="100%" x2="0%"><stop stop-color="%2300FA9A"/><stop offset="1" stop-color="%231CD8D2"/></linearGradient></defs><rect width="100%" height="100%" fill="url(%23g1)"/></svg>');
}