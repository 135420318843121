// Medium Screen
@media screen and (min-width: 1400px) {
    article, #projects, #about {
        max-width: var(--max-width-medium);
    }
}

// Big Screen
@media screen and (min-width: 1600px) {
    article, #projects, #about {
        max-width: var(--max-width);
    }
}

@media screen and (max-width: 1214px) {
    .project {
        margin-left: 4.5em;
        max-width: var(--max-width-mobile);
    }
}

@media screen and (max-width: 1025px) {
    #projects {
        width: 100%;
        padding: 0 3em;
    }
}

@media screen and (max-width: 1023px) {
    header {
        padding: 3em 2em;
    }

    .project {
        margin: 0;
    }

    #projects {
        padding: 0 2em;
    }

    #project-menu {
        position: relative;
        top: 0;
        left: 0;
        margin: 0 0 2em 0;

        ul {
            margin: 0 auto;
            width: fit-content;
            display: flex;
            flex-direction: row;

            li {
                a {
                    background: none;
                    width: 100%;

                    &:hover {
                        background: none;
                    }

                    .icon {
                        display: inline-block;
                        background: #333;
                    }
                }

                &.arrow-left, &.arrow-right, &.close {
                    transform: none;
                }

                &.arrow-left {
                    margin-right: 1em;
                    order: 1;
                }

                &.arrow-right {
                    order: 3;
                }

                &.close {
                    margin-right: 1em;
                    order: 2;
                }
            }
        }

    }

    footer {
        display: block;
        padding: 1.5em 0 0 0;

        .totop {
            width: 100%;

            .icon {
                text-align: center;

                svg {
                    width: 32px;
                    height: 26px;
                }
            }

            &:hover {
                transform: none;
                background: #333;
            }
        }

        .legal {
            transform: none;
            padding: 1em;
        }
    }
}

@media screen and (max-width: 767px) {
    #projects {
        padding: 0 1em;

        ul {
            grid-template-columns: 1fr;

            li {
                grid-column-start: 1;
                grid-column-end: 2;

                &:nth-child(2n) {
                    grid-column-start: 1;
                    grid-column-end: 2;
                }

                &:nth-child(3n) {
                    grid-column-start: 1;
                    grid-column-end: 2;
                }
            }
        }
    }

    #about {
        grid-template-columns: 1fr;

        #contact {
            grid-template-columns: 1fr;
        }
    }
}

@media screen and (max-width: 750px) {
    header {
        padding: 1.5em 1em;
    }

    p {
        font-size: 110%;
    }

    article {
        padding: 1em;
    }

    #project-menu {
        margin: 1em;
    }

    #projects {
        margin-top: 1em;
    }

    .project {
        h2 {
            line-height: 120%;
        }

        .content {
            display: grid;
            grid-template-columns: 1fr;
            grid-gap: 5em 5em;
        }

        .no-margin {
            margin: 0;
        }

        .left, .right, .full, .center, .wide-center,
        .wide-right, .wide-left, .super-wide-right, .super-wide-left,
        .three-left, .three-mid, .three-right {
            grid-column: auto;
        }
    }

    #projects {
        ul {
            grid-row-gap: 5em;

            li {
                a {
                    &:hover {
                        div {
                            transform: translate(0, -5px);
                        }
                    }
                }

                div {
                    width: 100%;
                    height: auto;
                    transform: translate(0, -5px);

                    .arrow {
                        display: none;
                    }
                }
            }
        }
    }
}