/* FONTS */
@font-face {
    font-family: 'Outfit';
    src: url('../fonts/woff2/outfit.woff2') format('woff2'),
         url('../fonts/woff/outfit.woff') format('woff');
    font-weight: 1 999;
}

@font-face {
    font-family: 'Interval';
    font-weight: normal;
    font-style: normal;
    src: url('../fonts/woff2/interval.woff2') format('woff2'),
         url('../fonts/woff/interval.woff') format('woff');
}