/*!
*     __ _____ _____ ____  _____ _____        ___
*  __|  |   | |   __|    \| __  | __  |   _ _| | |
* |  |  | | | |__   |  |  | __ -|    -|  | | |_  |
* |_____|_|___|_____|____/|_____|__|__|   \_/  |_|
*
* JNSDBR V4
* Portfolio of Jens de Boer
*
* If you got a job to offer or you're interested to work with me,
* feel free to drop me a line: hello@jnsdbr.de
*
* @version 4.2
* @author Jens de Boer <jens@jnsdbr.de>
*/
@charset 'UTF-8';
@use 'sass:meta';
@include meta.load-css('base');
@include meta.load-css('fonts');

@mixin border-bottom() {
	// box-shadow: inset 0 0px 0 #fff, inset 0 -3px 0 var(--ui-color);
	text-decoration: underline;
	text-decoration-thickness: 3px;
	text-decoration-color: var(--ui-color);
}

@mixin maxWidth($width) {
	margin: 0 auto;
	max-width: $width;
}

@mixin button() {
	background: var(--font-color-light);
	display: block;
	color: #fff;
	width: var(--button-width);
	height: var(--button-height);
	transition: all .15s linear;

	.name {
		display: none;
	}

	&:hover {
		background: var(--ui-color);
		border: 0;
	}
}

@mixin transition() {
	transition: all 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955);
	// transition-delay: 0.3s;
}

* {
	box-sizing: border-box;
	// font-feature-settings: "salt";
}

body {
	color: var(--font-color-light);
	font-family: var(--default-font), sans-serif;
	font-size: 100%;
	line-height: 150%;
	letter-spacing: 0.025em;
	margin: 0;
}

h1, h2, h3, h4 {
	font-family: var(--headline-font);
	letter-spacing: 0;
	font-weight: normal;
	text-transform: uppercase;
}

a {
	color: #000;
	text-decoration: none;
	transition: color .2s linear;

	&:hover {
		color: var(--ui-color);
	}
}

img {
	width: 100%;
	max-width: 100%;
	height: auto;
}

video {
	width: 100%;
	max-width: 100%;
	height: auto;
}

svg {
	fill: #fff;
	width: 100%;
}

p {
	font-size: 130%;
	line-height: 150%;
	font-weight: var(--content-font-weight);
	margin: 0 0 1em 0;

	&:last-child {
		margin: 0;
	}
}

ul {
	list-style: disc;
	padding-left: 2em;
	margin-bottom: 2em;
	line-height: 150%;
	font-size: 120%;
}

table {
	width: 100%;

	thead {
		text-align: left;
	}
}

/**
 * HEADER
 */
header {
	display: flex;
	padding: 3em;

	h1 {
		flex: 1;
		margin: 0;
		padding: 0.2em 0 0 0;
		text-transform: uppercase;
		font-size: 1.5em;
		letter-spacing: 0.2em;
	}

	nav {
		ul {
			list-style: none;
			margin: 0;
			padding: 0;

			li {
				display: inline-block;
				margin-right: 1em;
				font-weight: var(--menu-font-weight);

				&:last-child {
					margin: 0;
				}

				a {
					&.active {
						@include border-bottom();
					}
				}
			}
		}
	}
}

/**
 * CONTENT
 */
article {
	@include transition();
	@include maxWidth(var(--max-width-small));
	min-height: calc(100vh - 13em);
	padding: 0 2em;

	a {
		@include border-bottom();
	}

	h2 {
		margin: 1em 0 1em 0;
		font-size: 1.75em;
	}
}

/**
 * PROJECT OVERVIEW
 */
#projects {
	max-width: var(--max-width-small);

	ul {
		font-size: 100%;
		list-style: none;
		margin: 0;
		padding: 0;

		display: grid;
		grid-template-columns: repeat(8, 1fr);
		grid-row-gap: 10em;

		li {
			@include transition();
			grid-column-start: 1;
			grid-column-end: 6;

			a {
				color: var(--font-color-light);
				font-weight: var(--content-font-weight);
				text-decoration: none;
			}

			div {
				text-align: center;
				padding: 1.5em;

				h3 {
					margin: 0;
					color: #000;
					font-weight: 500;
					font-size: 1.4em;
				}

				p {
					margin: 0;
					color: var(--font-color-info-light);
				}
			}

			&:nth-child(2n) {
				grid-column-start: 4;
				grid-column-end: 9;
			}

			&:nth-child(3n) {
				grid-column-start: 2;
				grid-column-end: 7;
			}
		}
	}
}

/**
 * Project
 */
.project {
	h2 {
		margin: 0;
	}

	h3 {
		margin: 2em 0 0.5em 0;
		font-size: 1.25em;
	}

	.content {
		display: grid;
		grid-template-columns: repeat(6, 1fr);
		grid-gap: calc(2 * 5em) 5em;
		line-height: 150%;

		.category {
			line-height: 100%;
			color: var(--font-color-info-light);
			margin-top: 0.25em;
			font-style: italic;
			margin-bottom: 1.5em;
		}

		hr {
			margin: 5em 0 0 0;
			width: 2.5em;
			border: none;
			height: 3px;
			background: var(--font-color-light);
		}

		ul {
			font-weight: var(--content-font-weight);
		}
	}

	.left {
		grid-column: 1 / span 3;
	}

	.right {
		grid-column: 4 / span 3;
	}

	.full {
		grid-column: 1 / span 6;
	}

	.center {
		grid-column: 2 / span 3;
	}

	.wide-center {
		grid-column: 2 / span 4;
	}

	.wide-right {
		grid-column: 3 / span 4;
	}

	.wide-left {
		grid-column: 1 / span 4;
	}

	.super-wide-right {
		grid-column: 2 / span 5;
	}

	.super-wide-left {
		grid-column: 1 / span 5;
	}

	.three-left {
		grid-column: 1 / span 2;
	}

	.three-mid {
		grid-column: 3 / span 2;
	}

	.three-right {
		grid-column: 5 / span 2;
	}

	.no-margin {
		margin-top: calc(-1 * 5em);
	}

	.shadow {
		box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
	}
}

.video-container {
	position: relative;
	padding-bottom: 53.2%;
	padding-top: 30px;
	height: 0;
	overflow: hidden;
	margin-bottom: 1em;

	iframe, object, embed {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
	}
}

/* Project Menu */
#project-menu {
	transition: opacity 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955);
	position: absolute;
	top: 7.8em;
	left: 3em;

	&.hidden {
		opacity: 0;
		transform: none;
	}

	&.fixed {
		position: fixed;
		top: 4em;
		left: 3em;
	}

	.icon, svg {
		width: 32px;
		height: 32px;
	}

	ul {
		list-style: none;
		margin: 0;
		padding: 0;

		li {
			margin: 0 0 0.5em 0;

			a {
				@include button();

				div {
					transition: all 0.15s linear;
				}
			}

			&.arrow-left {
				&:hover {
					div {
						transform: translateX(-2px);
					}
				}
			}
			&.arrow-right {
				&:hover {
					div {
						transform: translateX(2px);
					}
				}
			}
			&.close {
				.icon {
					svg {
						transform: rotate(0deg);
						transition: all 0.15s linear;
					}
				}

				&:hover {
					.icon {
						svg {
							transform: rotate(90deg);
						}
					}
				}
			}
		}
	}
}

/**
 * ABOUT
 */
#about {
	max-width: var(--max-width-small);
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-column-gap: 4em;
	grid-row-gap: 2em;
	grid-auto-rows: min-content;
	min-height: calc(100vh - 151px);

	div {
		overflow: hidden;
	}

	p {
		margin-bottom: 4em;
	}

	img {
		margin-left: 50%;
		transform: translateX(-50%);
		max-width: 300px;
	}

	ul {
		li {
			font-weight: var(--content-font-weight);
			margin: 0 0 0.5em 0;
		}
	}

	#contact {
		margin: 0;
		padding: 0;
		list-style: none;
		display: grid;
		font-weight: var(--content-font-weight);
		grid-template-columns: repeat(2, 1fr);
	}
}

/**
 * FOOTER
 */
footer {
	padding: 3em 3em 0 3em;
	width: 100%;
	display: flex;

	.totop {
		@include button();
		position: absolute;
		padding: 0em 0.15em 0.1em 0.2em;

		div {
			transition: all 0.15s linear;
		}

		&:hover {
			div {
				transform: translateY(-2px);
			}
		}

	}

	.legal {
		flex: 1;
		text-align: center;
	}
}

/**
 * CONTENT FADE
 */
.hidden {
	opacity: 0;
	transform: translateY(30px);
}

/**
 * CONTENT ERROR
 */
#error-404 {
	display: grid;
    align-items: center;
    justify-content: center;
	text-align: center;

	h2 {
		font-size: 500%;
		margin: 0 0 0.5em 0;
	}
	p {
		margin: 0 0 0.25em 0;
	}
}

@include meta.load-css('mediaqueries');
//@include meta.load-css('dark-mode');
